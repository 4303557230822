import type React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import type { CombinedProductAttribute } from '../../../../Store/CombinedProduct/state';
import Flex from '../../../../../../stories/Atoms/Flex/Flex';
import styles from './List.module.scss';

interface SelectListProps {
    attribute: CombinedProductAttribute;
    disabledValues: number[];
    onChange: (attributeId: number, valueId: number) => void;
    selectedValues: Record<number, number>;
    valuePrices: Record<number, { hasDiscount: boolean; price: string }>;
}

const List: React.FC<SelectListProps> = ({ attribute, disabledValues, onChange, selectedValues, valuePrices }) => {
    const isDisabledValue = (disabledValueId: number) => disabledValues.includes(disabledValueId);
    const selectedValueId = selectedValues[attribute.id] && !isDisabledValue(selectedValues[attribute.id] ?? 0) ? selectedValues[attribute.id] : 0;
    const [selectedValue, setSelectedValue] = useState<number>(Number(selectedValueId));

    useEffect(() => {
        if (selectedValueId && !Object.keys(valuePrices).includes(String(selectedValue))) {
            setSelectedValue(selectedValueId);
        }
    }, [selectedValueId, selectedValue, valuePrices]);

    const updateValue = (valueId: number) => {
        setSelectedValue(valueId);
        onChange(attribute.id, valueId);
    };

    return (
        <div className={styles.List}>
            {attribute.values.map((value) => (
                !isDisabledValue(value.id) && (
                    <label className={classNames([styles.List__Item, 'p-4'])} key={`row-${value.id}`} htmlFor={`value_${value.id}`}>
                        <Flex justify="spaceBetween">
                            <Flex alignItems="center">
                                <input
                                    type="radio"
                                    name={`attribute_${attribute.id}`}
                                    id={`value_${value.id}`}
                                    className="form-radio"
                                    value={value.id}
                                    checked={value.id === selectedValue}
                                    onChange={() => updateValue(value.id)}
                                    readOnly
                                />
                                <div className={classNames([styles.List__Item__Name, 'ml-3'])}>{value.name}</div>
                            </Flex>
                            <div className={styles.List__Item__Price}>
                                {valuePrices[value.id] && String(valuePrices[value.id]?.price)}
                            </div>
                        </Flex>
                    </label>
                )
            ))}
        </div>
    );
};

export default List;
