import type React from 'react';
import type { CombinedProductAttribute } from '../../../../Store/CombinedProduct/state';
import useTranslate from '../../../../../general/Translation/hooks/UseTranslate';

interface ImageProps {
    attribute: CombinedProductAttribute;
    disabledValues: number[];
    onChange: (attributeId: number, valueId: number) => void;
    selectedValues: Record<number, number>;
    valuePrices: Record<number, { hasDiscount: boolean; ourChoice: boolean; price: string }>;
}

const Image: React.FC<ImageProps> = ({ attribute, disabledValues, onChange, selectedValues, valuePrices }) => {
    const translate = useTranslate();

    return (<>
        {attribute.values.map((value) => {
            let imageClassName = 'combinedProductAttributeImage';
            if (disabledValues.includes(value.id)) {
                imageClassName += ' hidden';
            }

            let location = value.image;
            if (!disabledValues.includes(value.id) && selectedValues[attribute.id] === value.id) {
                imageClassName += ' selected';
                location = value.selectedImage;
            }

            return (
                <div className={imageClassName} onClick={() => onChange(attribute.id, value.id)} data-testid="image" key={value.id}>
                    <div className="relative">
                        <img src={location} alt={value.name} />
                        {valuePrices[value.id]?.ourChoice && (
                            <div className="ourChoice">
                                {translate('productinformatie', 'ourChoice')}
                            </div>
                        )}
                    </div>
                    <div className="valueName">{value.name}</div>
                    {valuePrices[value.id] && <div className="valuePrice">{String(valuePrices[value.id]?.price)}</div>}
                </div>
            );
        })}
    </>
    );
};

export default Image;
