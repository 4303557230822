import type React from 'react';
import Translation from '../../../general/Translation/Translation';
import { useTypedSelector } from '../../Store/connectors';
import NotificationBox from '../../../../stories/Molecules/NotificationBox/NotificationBox';

type Props = {
    className?: string;
};

const DeliveryStatusInfo: React.FC<Props> = ({ className = 'mt-6 mb-6' }) => {
    const { isSoldOut } = useTypedSelector(({ product }) => product);
    const { deadlineText, isDelayed, isOutOfStock, outOfStockMessage } = useTypedSelector(({ shipping }) => shipping);

    if (isSoldOut) {
        return (
            <NotificationBox className={className} type="error">
                <span><Translation pageString="productinformatie" stringId="sold_out" /></span>
            </NotificationBox>
        );
    }

    if (isOutOfStock || isDelayed) {
        return (
            <NotificationBox className={className} type="error">
                <span>{outOfStockMessage}</span>
            </NotificationBox>
        );
    }

    return (
        <NotificationBox className={className} type="success">
            <span className="mb-1"><Translation pageString="productinformatie" stringId="in_stock"/></span>
            <span>{deadlineText}</span>
        </NotificationBox>
    );
};

export default DeliveryStatusInfo;
