import type React from 'react';
import type { CombinedProductAttribute } from '../../../../Store/CombinedProduct/state';
import ButtonGroupItem from './ButtonGroupItem';

interface ButtonGroupProps {
    attribute: CombinedProductAttribute;
    disabledValues: number[];
    onChange: (attributeId: number, valueId: number) => void;
    selectedValues: Record<number, number>;
    valuePrices: Record<number, { hasDiscount: boolean; price: string }>;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ attribute, disabledValues, onChange, selectedValues, valuePrices }) => (
    <div className="radioBar">
        {attribute.values.map((value) => (
            <ButtonGroupItem key={value.id} value={value} attribute={attribute} disabledValues={disabledValues} selectedValues={selectedValues} valuePrices={valuePrices} onChange={onChange} />
        ))}
    </div>
);

export default ButtonGroup;
