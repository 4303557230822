import { hydrateRoot } from 'react-dom/client';
import { document, window } from '../../js/globals';
import ProductPage from '../../js/legacy/productpage';
import recentlyViewed from '../../js/productPage/recentlyViewed';
import $ from '../../js/util/fancybox-jquery';
import ProductInformation from './Components/ProductInformation/ProductInformation';
import type { ProductPageRootState } from './Store/store';
import { createStore, initialProductPageState } from './Store/store';
import { Environment } from './Store/Environment/state';
import initialUiState from './Store/UI/state';
import { getInitialPrimaryButton } from './Store/Middleware/primaryCtaButtonMiddleware';

const container = document?.getElementById('product-page');

const { PRODUCT_INFORMATION_STATE } = window as Window;

if (container && PRODUCT_INFORMATION_STATE) {
    const { contextData, ...productPageData } = PRODUCT_INFORMATION_STATE;

    const productPageState: ProductPageRootState = {
        ...initialProductPageState,
        ...productPageData,
        environment: {
            environment: Environment.BROWSER,
        },
    };

    const store = createStore({
        ...productPageState,
        ui: {
            ...initialUiState,
            primaryCtaButton: getInitialPrimaryButton(productPageState),
        },
    });

    hydrateRoot(
        container, (
            <ProductInformation
                store={store}
                now={new Date()}
                locale={store.getState().meta.locale}
                webshopContextData={contextData}
            />
        ),
    );

    const event = new CustomEvent('product-page-loaded');
    document?.dispatchEvent(event);

    $(() => {
        ProductPage.init();

        const productId = document?.getElementById('productpage')?.dataset?.productId;
        if (productId) {
            recentlyViewed(parseInt(productId, 10));
        }

        delete (window as Window).PRODUCT_INFORMATION_STATE;
    });
}
