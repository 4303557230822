export type CombinedProductValue = {
    id: number;
    image: string;
    name: string;
    selectedImage: string;
    value: string;
};

export type CombinedProductAttribute = {
    id: number;
    level: number;
    name: string;
    type: string;
    values: CombinedProductValue[];
};

type CombinedProductState = {
    attributes: CombinedProductAttribute[];
    disabledValues: number[];
    id: number;
    selectedValues: Record<number, number> | Record<never, never>;
    valuePrices: Record<number, { hasDiscount: boolean; ourChoice: boolean; price: string }> | Record<never, never>;
} | null;

const initialCombinedProductState: CombinedProductState = null;

export type { CombinedProductState };
export default initialCombinedProductState;
