import type React from 'react';
import { useCombinedProduct } from '../../../Hooks/UseCombinedProduct';
import ColorSelectBox from './Attribute/ColorSelectBox';
import ButtonGroup from './Attribute/ButtonGroup';
import Image from './Attribute/Image';
import SelectList from './Attribute/SelectList';
import { useTypedSelector } from '../../../Store/connectors';
import List from './Attribute/List';

const CombinedProduct: React.FC = () => {
    const combinedProduct = useTypedSelector((state) => state.combinedProduct);
    const showUnpublished = useTypedSelector(({ meta }) => meta.showUnpublished);
    const variantId = useTypedSelector(({ product }) => product.variantId);

    const { updateCombinedProduct } = useCombinedProduct();

    if (combinedProduct === null) {
        return null;
    }

    const lastAttributeId = combinedProduct.attributes[combinedProduct.attributes.length - 1]?.id;

    const onChange = (attributeId: number, valueId: number) => {
        const selections = { ...combinedProduct.selectedValues };
        selections[attributeId] = valueId;

        const data = {
            attributes: selections,
            combinedProductId: combinedProduct.id,
            showUnpublished,
            variantId,
        };
        updateCombinedProduct(data);
    };

    return (
        <div className="combinedProductData" data-show-unpublished={showUnpublished}>
            {combinedProduct.attributes.map((attribute) => (
                <div key={attribute.id} className={attribute.values.length > 1 ? 'combinedProductAttributeRow' : 'combinedProductAttributeRow hidden'}>
                    <div className="attributeTitle">{attribute.name}</div>
                    <div className="attributeValues">
                        {attribute.type === 'colorselectbox' && (
                            <ColorSelectBox
                                attribute={attribute}
                                selectedValues={combinedProduct.selectedValues}
                                disabledValues={combinedProduct.disabledValues}
                                onChange={onChange}
                            />
                        )}
                        {attribute.type === 'buttongroup' && (
                            <ButtonGroup
                                attribute={attribute}
                                selectedValues={combinedProduct.selectedValues}
                                disabledValues={combinedProduct.disabledValues}
                                valuePrices={combinedProduct.valuePrices} onChange={onChange}
                            />
                        )}
                        {attribute.type === 'image' && (
                            <Image
                                attribute={attribute}
                                selectedValues={combinedProduct.selectedValues}
                                disabledValues={combinedProduct.disabledValues}
                                valuePrices={combinedProduct.valuePrices}
                                onChange={onChange}
                            />
                        )}
                        {attribute.type === 'selectlist' && lastAttributeId !== attribute.id && (
                            <SelectList
                                attribute={attribute}
                                selectedValues={combinedProduct.selectedValues}
                                disabledValues={combinedProduct.disabledValues}
                                valuePrices={combinedProduct.valuePrices}
                                onChange={onChange}
                            />
                        )}
                        {attribute.type === 'selectlist' && lastAttributeId === attribute.id && (
                            <List
                                attribute={attribute}
                                selectedValues={combinedProduct.selectedValues}
                                disabledValues={combinedProduct.disabledValues}
                                valuePrices={combinedProduct.valuePrices}
                                onChange={onChange}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CombinedProduct;
