import type React from 'react';
import type { CombinedProductAttribute } from '../../../../Store/CombinedProduct/state';

interface ColorSelectBoxProps {
    attribute: CombinedProductAttribute;
    disabledValues: number[];
    onChange: (attributeId: number, valueId: number) => void;
    selectedValues: Record<number, number>;
}

const ColorSelectBox: React.FC<ColorSelectBoxProps> = ({ attribute, disabledValues, onChange, selectedValues }) => (
    <>
        {attribute.values.map((value) => {
            let colorClassName = 'combinedProductAttributeColor';
            if (disabledValues.includes(value.id)) {
                colorClassName += ' hidden';
            }

            if (!disabledValues.includes(value.id) && selectedValues[attribute.id] === value.id) {
                colorClassName += ' selected';
            }

            return <div key={value.id}
                className={colorClassName}
                data-attribute-id={attribute.id}
                data-value-id={value.id}
                data-testid="color"
                style={{ backgroundColor: `#${value.value}` }}
                onClick={() => onChange(attribute.id, value.id)}
            >
            </div>;
        })}
    </>
);

export default ColorSelectBox;
