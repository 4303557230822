import type React from 'react';
import { useContext, useState } from 'react';
import classNames from 'classnames';
import useEditorType from '../../Hooks/UseEditorType';
import { useOnPageEditorHistoryListener } from '../../Hooks/UseOnPageEditorHistoryListener';
import Delivery from '../Delivery/Delivery';
import CombinedProduct from './CombinedProduct/CombinedProduct';
import CtaButtons from './CtaButtons/CtaButtons';
import MobileQuickEditorOverlay from './MobileQuickEditorOverlay/MobileQuickEditorOverlay';
import PriceBlock from './PriceBlock/PriceBlock';
import Rating from './Rating/Rating';
import SizeChart from './SizeChart/SizeChart';
import TemperatureWarning from '../TemperatureWarning/TemperatureWarning';
import ShippingMethods from '../ShippingMethods/ShippingMethods';
import ShoppingAward from '../../../general/ShoppingAward/ShoppingAward';
import { useShowOnPageEditor } from '../../Hooks/useShowOnPageEditor';
import styles from './InformationBlock.module.scss';
import CoolerNotification from '../CoolerNotification/CoolerNotification';
import { useTypedSelector } from '../../Store/connectors';
import GiftEditorLoader from '../../../gift-editor/Components/Loader/GiftEditorLoader';
import { WebshopContext } from '../../../general/WebshopContext/WebshopContextProvider';
import { pdpMoveDeliveryStatus } from '../../../../js/optimizely/fx/flags';

const InformationBlock: React.FC = () => {
    const hasPaidCoolerNotification = useTypedSelector(({ shipping }) => shipping.hasPaidCoolerNotification);
    const hasFreeCoolerNotification = useTypedSelector(({ shipping }) => shipping.hasFreeCoolerNotification);
    const hasTemperatureNotification = useTypedSelector(({ shipping }) => shipping.hasTemperatureNotification);
    const isSoldOut = useTypedSelector(({ product }) => product.isSoldOut);
    const productId = useTypedSelector(({ product }) => product.productId);
    const productSubtitle = useTypedSelector(({ product }) => product.productSubtitle);
    const productTitle = useTypedSelector(({ product }) => product.productTitle);
    const isCartEdit = useTypedSelector(({ cart }) => cart.isCartEdit);
    const isSvgCartEdit = useTypedSelector(({ cart }) => cart.isSvgCartEdit);
    const giftId = useTypedSelector(({ cart }) => cart.giftId);
    const showShoppingAward = useTypedSelector(({ meta }) => meta.showShoppingAward);
    const { isQuickEditorProduct, isSvgEditorProduct } = useEditorType();
    const [showAddToCartButton, setShowAddToCartButton] = useState<boolean>(isCartEdit);
    const context = useContext(WebshopContext);
    const abTestMoveDeliveryStatus = context.experimentVariation === pdpMoveDeliveryStatus.variationB
        && context.experimentFlag === pdpMoveDeliveryStatus.flagKey;

    const { closeOnPageEditor, openOnPageEditor, shouldShowQuickEditor, shouldShowSvgEditor } = useShowOnPageEditor();

    useOnPageEditorHistoryListener();

    const onCloseQuickEditorOverlayRequest = () => {
        closeOnPageEditor();
        setShowAddToCartButton(true);
    };

    return (
        <div data-testid='mainInformationBlock' className={classNames('product-information__content')}>
            <div className="product-information-desktop">

                <div data-testid='informationBlockTitles'>
                    <h1 className="h3">{productTitle}</h1>
                    {productSubtitle && <h2 className={styles.InformationBlock_titles__subtitle}>{productSubtitle}</h2>}
                    <Rating/>
                    {!abTestMoveDeliveryStatus && <Delivery/> }
                    {(hasPaidCoolerNotification || hasFreeCoolerNotification) && <CoolerNotification isFree={hasFreeCoolerNotification}/>}
                </div>
                <div className="priceDetailsWrapper">
                    <CombinedProduct/>
                    {!isSoldOut && (
                        <div id="editor-widget-container">
                            {isQuickEditorProduct && (
                                <MobileQuickEditorOverlay
                                    onClose={onCloseQuickEditorOverlayRequest}
                                    showQuickEditor={shouldShowQuickEditor}
                                />
                            )}
                        </div>
                    )}
                    <SizeChart/>
                    <PriceBlock isSoldOut={isSoldOut}/>
                    {!isSoldOut && (
                        <CtaButtons
                            isCartEdit={isCartEdit || isSvgCartEdit}
                            onStartPersonalising={openOnPageEditor}
                            showAddToCartButton={showAddToCartButton}
                        />
                    )}
                    {abTestMoveDeliveryStatus && <Delivery/> }
                    {(hasTemperatureNotification && !(hasFreeCoolerNotification)) && <TemperatureWarning/>}
                </div>
            </div>
            <ShippingMethods/>
            {showShoppingAward && <ShoppingAward showOnMobile={false} showOnDesktop/>}
            {!isSoldOut && isSvgEditorProduct && <GiftEditorLoader
                context={context}
                isOpen={shouldShowSvgEditor}
                onClose={closeOnPageEditor}
                productId={productId}
                giftId={giftId ?? undefined}
            />}
        </div>
    );
};

export default InformationBlock;
