import type { ProductInformationMeta } from '../../../../ssr/src/Outlet/ProductInformation/ProductInformationSchema';

export enum EditorType {
    ADVANCED_EDITOR = 'ADVANCED_EDITOR',
    QUICK_EDITOR = 'QUICK_EDITOR',
    SVG_EDITOR = 'SVG_EDITOR',
}

export enum Version {
    A = 'A',
    B = 'B',
}

const initialMetaDataState: ProductInformationMeta = {
    cdnUrl: '',
    language: 'EN',
    locale: 'nl-NL',
    partnerId: 0,
    showShoppingAward: false,
    showUnpublished: false,
    siteUrl: '',
    svgEditorAbTestVersion: Version.A,
};

export default initialMetaDataState;
