import classNames from 'classnames';
import type React from 'react';
import { useContext } from 'react';
import DeliveryStatusInfo from './DeliveryStatusInfo';
import { useTypedSelector } from '../../Store/connectors';
import { WebshopContext } from '../../../general/WebshopContext/WebshopContextProvider';
import { pdpMoveDeliveryStatus } from '../../../../js/optimizely/fx/flags';

const Delivery: React.FC = () => {
    const isDealPromotion = useTypedSelector(({ product }) => product.isDealPromotion);
    const testDateTime = useTypedSelector(({ shipping }) => shipping.testDateTime);
    const context = useContext(WebshopContext);
    const abTestMoveDeliveryStatus = context.experimentVariation === pdpMoveDeliveryStatus.variationB
        && context.experimentFlag === pdpMoveDeliveryStatus.flagKey;

    return (
        <>
            <div className={classNames('deliveryStatus', { hidden: isDealPromotion })}>
                <DeliveryStatusInfo className={abTestMoveDeliveryStatus ? 'mt-6' : 'mt-6 mb-6'}/>
            </div>
            {testDateTime && (
                <div className="testingDeliveryString">
                    Testing for: {testDateTime}
                </div>
            )}
        </>
    );
};

export default Delivery;
