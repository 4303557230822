import { createReducer } from '@reduxjs/toolkit';
import { combinedProductLoaded, updateDisabledValues, updateSelectedValues, updateValuePrices } from './action';
import initialCombinedProductState from './state';

const combinedProductReducer = createReducer(initialCombinedProductState, (builder) => {
    builder.addCase(combinedProductLoaded, (state, { payload }) => payload);
    builder.addCase(updateDisabledValues, (state, { payload }) => (state ? { ...state, disabledValues: payload } : null));
    builder.addCase(updateSelectedValues, (state, { payload }) => (state ? { ...state, selectedValues: payload } : null));
    builder.addCase(updateValuePrices, (state, { payload }) => (state ? { ...state, valuePrices: payload } : null));
});

export default combinedProductReducer;
