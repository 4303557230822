import type React from 'react';
import type { CombinedProductAttribute } from '../../../../Store/CombinedProduct/state';

interface SelectListProps {
    attribute: CombinedProductAttribute;
    disabledValues: number[];
    onChange: (attributeId: number, valueId: number) => void;
    selectedValues: Record<number, number>;
    valuePrices: Record<number, { hasDiscount: boolean; price: string }>;
}

const SelectList: React.FC<SelectListProps> = ({ attribute, disabledValues, onChange, selectedValues, valuePrices }) => {
    const selectedValueId = selectedValues[attribute.id] && !disabledValues.includes(selectedValues[attribute.id] ?? 0) ? selectedValues[attribute.id] : 0;

    return (
        <div className="attributeSelect">
            <select className="combinedProductAttribute" onChange={(event) => onChange(attribute.id, Number(event.target.value))} value={selectedValueId}>
                {attribute.level > 1 && <option value="0">-</option>}
                {attribute.values.map((value) => (
                    !disabledValues.includes(value.id) && <option key={value.id} value={value.id}>
                        {value.name}
                        {valuePrices[value.id] && ` (${String(valuePrices[value.id]?.price)})`}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SelectList;
