import type { UnknownAction } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';
import type { CombinedProductState } from './state';

interface CombinedProductAction extends UnknownAction {
    payload: string;
    type: CombinedProductActionType;
}

export enum CombinedProductActionType {
    COMBINED_PRODUCT_LOADED = 'COMBINED_PRODUCT_LOADED',
    UPDATE_DISABLED_VALUES = 'UPDATE_DISABLED_VALUES',
    UPDATE_SELECTED_VALUES = 'UPDATE_SELECTED_VALUES',
    UPDATE_VALUE_PRICES = 'UPDATE_VALUE_PRICES',
}

export const combinedProductLoaded = createAction<CombinedProductState, CombinedProductActionType>(CombinedProductActionType.COMBINED_PRODUCT_LOADED);
export const updateDisabledValues = createAction<number[], CombinedProductActionType>(CombinedProductActionType.UPDATE_DISABLED_VALUES);
export const updateSelectedValues = createAction<number[], CombinedProductActionType>(CombinedProductActionType.UPDATE_SELECTED_VALUES);
export const updateValuePrices = createAction<Record<number, { hasDiscount: boolean; price: string }>, CombinedProductActionType>(CombinedProductActionType.UPDATE_VALUE_PRICES);

export type { CombinedProductAction };
